<template>
	<el-dialog top="3vh" title="绑定银行卡" width="500px" :visible.sync="is_show_in_page">
		<el-form status-icon label-position="left" label-width="70px">
			<el-form-item label="用户手机" >
				<el-input class="el_input" v-model="data.user_tel" type="number" >
					<el-button @click="app_user_ser" slot="append" style="width:112px">搜索</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="用户姓名" >
				<el-input class="el_input" v-model="data.user_name" :disabled="true"></el-input>
			</el-form-item>
			<el-form-item label="银行卡号" >
				<el-input class="el_input" v-model="data.bank_card" type="number"></el-input>
			</el-form-item>
		</el-form>
		<div style="text-align:center">
			<el-button type="primary" @click="add_view_sub">提交</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number
		},
		data() {
			return {

				data:{
					user_num:'',
					user_tel:'',
					user_name:'',
					bank_card:''
				},
				is_show_in_page:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}
			}
		},
		methods: {

			//提交
			add_view_sub(){

				if(!this.bind.data.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'缺少用户信息'
					});
					return;
				}

				if(!this.$my.check.is_bank_card(this.bind.data.bank_card)){
					this.$my.other.msg({
						type:'warning',
						str:'缺少银行卡号'
					});
					return;
				}

				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'bankcard_bind_by_admin',
						user_num:this.data.user_num,
						card_num:this.data.bank_card,
					},
					callback:(data)=>{
						
						//关闭弹窗
						this.bind.is_show=false;

						//刷新
						this.get_page_data()
					}
				});
			},

			//搜索app用户
			app_user_ser(){

				if(!this.$my.check.is_tel(this.bind.data.user_tel)){
					this.$my.other.msg({
						type:'warning',
						str:'请输入正确的电话号码'
					});
					return;
				}

				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.bind.data.user_tel
					},
					callback:(data)=>{

						console.log(data);
						return;

						this.bind.data.user_num=data.list[0].user_num
						this.bind.data.user_name=data.list[0].name
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.el_input{
		width:94%
	}
</style>